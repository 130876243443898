import React, { Component } from "react"
import { getItems, postItem } from "../services/collectionServices"
import { apiStore2SelectStores } from "../utils/functions"
import { FaTimes } from "react-icons/fa"
import { MsgDialog } from "../components/common/dialogs"
import { msgYesNoData } from "../config.json"
import SpinnerLoading from "../components/common/spinnerLoading"

class Stores extends Component {
  state = {
    stores: [],
    msgYesNoData: { ...msgYesNoData },
    storesLoading: true,
  }

  async loadStores() {
    this.setState({ storesLoading: true })
    let stores = await getItems("stores")
    stores = apiStore2SelectStores(stores.data)
    this.setState({ stores, storesLoading: false })
  }

  componentDidMount = () => {
    this.loadStores()
  }

  componentWillReceiveProps(nextProps) {
    this.loadStores()
  }

  handleRemoveStore = store => {
    const obj = { ...store }
    this.setState({
      msgYesNoData: {
        open: true,
        title: "Eliminazione punto vendita",
        text: "Confermi l'eliminazione del punto vendita: " + store.label + "?",
        obj,
      },
    })
  }

  handleMsgYesNoClickNo = e => {
    e.preventDefault()
    this.setState({ msgYesNoData: { open: false } })
  }

  handleMsgYesNoClickYes = async e => {
    e.preventDefault()
    const { obj } = this.state.msgYesNoData
    if (await postItem("clients/store/" + obj.id + "/remove", obj)) {
      this.loadStores()
    }
    this.setState({ msgYesNoData: { open: false } })
  }

  render() {
    const { stores, storesLoading, msgYesNoData } = this.state
    return (
      <div>
        <MsgDialog
          dialogObj={msgYesNoData}
          onMsgDialogClickYes={this.handleMsgYesNoClickYes}
          onMsgDialogClickNo={this.handleMsgYesNoClickNo}
        />
        <h2 className={"customers-area-title"}>Punti vendita</h2>
        {!storesLoading && stores.length == 0 && (
          <span className="text-danger">
            Non hai alcun punto vendita associato a questo account. Contatta il
            servizio clienti per maggiori informazioni!
          </span>
        )}

        {storesLoading && <SpinnerLoading />}
        {!storesLoading && stores.length > 0 && (
          <table className="">
            <thead>
              <tr>
                <th>Punto vendita</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {stores.map(store => (
                <tr key={store.id}>
                  <td>
                    <div className={"store-item"}>
                      <span className={"store-item-fullname"}>
                        {store.value}
                      </span>
                      <span className={"store-item-address"}>
                        {store.fullAddress}
                      </span>
                    </div>
                  </td>
                  <td className="r" title="Elimina questo punto vendita">
                    {store.primary && <span>SEDE PRINCIPALE</span>}
                    {!store.primary && (
                      <FaTimes
                        className="text-orange c-pointer"
                        onClick={() => this.handleRemoveStore(store)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

export default Stores
