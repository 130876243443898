import React from "react"
import Joi from "joi-browser"
import Form from "./common/form"
import { setItem } from "../services/collectionServices"
import { toast } from "react-toastify"

class AddNewStore extends Form {
  state = {
    data: { store_code: "" },
    errors: {},
    storeAdding: false,
  }

  schema = {
    store_code: Joi.string()
      .required()
      .label("Codice punto vendita"),
  }

  doSubmit = async () => {
    if (this.state.storeAdding) {
      return
    }
    this.setState({ storeAdding: true }, async () => {
      const errors = this.validate()
      this.setState({ errors: errors || {} })
      if (errors) {
        this.setState({ storeAdding: false })
        toast.error("Sono stati riscontrati degli errori di compilazione")
        return
      }

      const { data } = this.state
      data.id = 0
      if (await setItem("clients/store/add", data)) {
        this.props.handleNewStoreAdded()
        toast.success("Punto vendita aggiunto con successo")
      }
    })
    this.setState({ storeAdding: false })
  }

  render() {
    return (
      <div className="">
        <h2 className={"customers-area-title mt-5"}>Aggiungi punto vendita</h2>

        <form onSubmit={this.handleSubmit} className="pb-5">
          <div className="row">
            <div className="col-12 col-md-6">
              {this.renderInput(
                "store_code",
                "Codice punto vendita",
                "text",
                false,
                "bootstrap"
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              {this.renderButton("Aggiungi", true)}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default AddNewStore
