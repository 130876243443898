import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UserAreaMenu from "../components/userAreaMenu"
import Stores from "../components/stores"
import AddNewStore from "../components/addNewStore"
import { getCurrentUser } from "../services/authService"
import { anonymousUser } from "../config.json"

class PuntiVendita extends Component {
  state = {
    user: { ...anonymousUser },
    reloadStores: false,
  }

  async componentDidMount() {
    const user = await getCurrentUser()
    this.setState({ user })
    if (user.id === 0) {
      window.location = "/login"
    }
  }

  handleNewStoreAdded = () => {
    const { reloadStores } = this.state
    this.setState({ reloadStores: !reloadStores })
  }

  render() {
    const { user } = this.state
    return (
      <Layout>
        <SEO
          title="Punti Vendita"
          keywords={[`vision care`]}
          description="Punti Vendita"
        />

        {user.id > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12 pt-3">
                <h1 className={"page-title"}>Area Clienti</h1>

                <div className="row">
                  {/*  LEFT */}
                  <div className="col-12">
                    <UserAreaMenu menuItemSelected="Punti vendita" />
                  </div>

                  {/*  CENTER */}
                  <div className="col-12">
                    <Stores reloadStores={this.state.reloadStores} />
                    <AddNewStore
                      handleNewStoreAdded={this.handleNewStoreAdded}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default PuntiVendita
