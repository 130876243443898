import React, { Component } from "react"
import { FaShoppingCart } from "react-icons/fa"
import { getCurrentUser } from "../services/authService"
import { getLocalCart, sIsNumber } from "../utils/functions"

const items = [
  {
    label: "Dashboard",
    path: "/area-clienti",
    selected: false,
    onlyClientShow: false,
  },
  {
    label: "Punti vendita",
    path: "/punti-vendita",
    selected: false,
    onlyClientShow: true,
  },
  { label: "Ordine", path: "/ordine", selected: false, onlyClientShow: false },
  {
    label: "Storico ordini",
    path: "/storico-ordini",
    selected: false,
    onlyClientShow: false,
  },
  {
    label: "Carrello",
    path: "/carrello",
    selected: false,
    onlyClientShow: false,
  },
]

class UserAreaMenu extends Component {
  state = {
    items: [...items],
    userIsClient: false,
    cartQuantity: 0,
  }

  refreshCartQuantity() {
    let cart = getLocalCart()
    let cartQuantity = 0
    for (let i = 0; i < cart.products.length; i++) {
      if (sIsNumber(cart.products[i].quantity)) {
        cartQuantity += parseInt(cart.products[i].quantity)
      }
      if (sIsNumber(cart.products[i].discountedQuantity)) {
        cartQuantity += parseInt(cart.products[i].discountedQuantity)
      }
    }
    this.setState({ cartQuantity })
  }

  componentDidMount = async () => {
    const { menuItemSelected } = this.props
    const items = [...this.state.items]
    for (let i = 0; i < items.length; i++) {
      if (items[i].label === menuItemSelected) {
        items[i].selected = true
      }
    }
    this.refreshCartQuantity()
    const user = await getCurrentUser()
    let userIsClient = true
    try {
      userIsClient = user.relationships.roles.data[0].id === 3
    } catch (error) {}

    this.setState({ items, userIsClient })
  }

  componentWillReceiveProps(props) {
    this.refreshCartQuantity()
  }

  handleMenuClick = (e, item) => {
    e.preventDefault()
    window.location = item.path
  }

  render() {
    const { items, userIsClient, cartQuantity } = this.state

    return (
      <div className="mb-5">
        <ul className="list-group list-group-horizontal-md m-0">
          {items.map(
            (item, index) =>
              ((item.onlyClientShow === true && userIsClient === true) ||
                item.onlyClientShow === false) && (
                <li
                  key={index}
                  className={
                    "list-group-item userAreaMenuItem list-group-flush " +
                    (item.selected === true ? "userAreaMenuItemSelected" : "") +
                    (item.label === "Carrello"
                      ? " with-icon list-group-item-cart"
                      : "")
                  }
                  onClick={e => this.handleMenuClick(e, item)}
                >
                  {item.label === "Carrello" && <FaShoppingCart />} {item.label}
                  {item.label === "Carrello" && cartQuantity > 0 && (
                    <span className="cart-quantity">{cartQuantity}</span>
                  )}
                </li>
              )
          )}
        </ul>
      </div>
    )
  }
}

export default UserAreaMenu
